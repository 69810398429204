export const TeacherIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="17"
    viewBox="0 0 19 17"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_2957_7459)">
      <path
        d="M18.334 10.9191C18.2478 10.9185 18.1622 10.9185 18.0766 10.9191H18.0286V1.10273C18.0636 1.10273 18.0986 1.10402 18.1337 1.10467C18.262 1.10726 18.3839 1.1092 18.5071 1.09949C18.7625 1.07942 18.9324 0.927897 18.9862 0.671479C19.0445 0.394987 18.9188 0.148282 18.6737 0.0576293C18.5434 0.00906528 18.3969 0.00259008 18.2711 0.00259008C13.2966 0 8.32215 0 3.34767 0H1.74894C1.46498 0 1.25752 0.064752 1.13175 0.192961C1.03904 0.287499 0.993012 0.413766 0.996254 0.567228C1.00339 0.929192 1.24845 1.10596 1.74505 1.10726C1.8598 1.10726 1.95121 1.10726 1.97001 1.12733C1.98946 1.14741 1.98946 1.24389 1.98946 1.35656V4.28917V4.33709C1.98946 4.39407 1.98946 4.453 1.9927 4.51192C2.0154 4.85835 2.22415 5.07526 2.53858 5.0785C2.54053 5.0785 2.54247 5.0785 2.54506 5.0785C2.86987 5.0785 3.09872 4.84021 3.10326 4.49703C3.10909 4.00038 3.10844 3.49596 3.10715 3.00773C3.10715 2.80894 3.1065 2.61015 3.1065 2.41201V1.47376V1.10791H5.60184C9.23237 1.10791 12.9861 1.10791 16.6782 1.10337H16.6802C16.7826 1.10337 16.8416 1.11568 16.8695 1.14417C16.8973 1.17201 16.9103 1.23029 16.9103 1.32742C16.9045 3.709 16.9045 6.13007 16.9045 8.47216V10.7074C16.9045 10.7631 16.9012 10.8181 16.898 10.8822C16.8973 10.8945 16.8967 10.9075 16.896 10.9204H15.0484C14.2321 10.9204 13.4159 10.9211 12.5997 10.9198H12.5971C12.4033 10.9198 12.131 10.9535 11.989 11.2371C11.9021 11.4106 11.9066 11.5984 12.0013 11.7525C12.1102 11.9299 12.3229 12.0361 12.5692 12.0381C12.9595 12.0406 13.3537 12.0419 13.7453 12.0419C14.0078 12.0419 14.2691 12.0419 14.5265 12.0413C14.7884 12.0413 15.0497 12.0406 15.3116 12.0406H16.1181C16.8597 12.0413 17.602 12.0419 18.3437 12.0393C18.5654 12.0387 18.7418 11.9778 18.8559 11.8626C18.9512 11.7661 19.0011 11.6321 18.9992 11.4747C18.9959 11.127 18.7534 10.925 18.334 10.9204V10.9191Z"
        fill="currentColor"
      />
      <path
        d="M13.2986 8.59128C13.3537 8.52588 13.4094 8.46048 13.4646 8.39508C13.6111 8.22284 13.7628 8.04413 13.9035 7.86088C14.0941 7.61158 14.0591 7.28912 13.8211 7.09357C13.5923 6.90579 13.2688 6.93298 13.0509 7.15702C12.9984 7.21077 12.9511 7.26904 12.9044 7.32538C12.8889 7.34416 12.8733 7.36294 12.8577 7.38171L7.04824 14.3128C7.01258 14.3549 6.97627 14.3963 6.93349 14.4449C6.91533 14.3892 6.90172 14.3464 6.88681 14.3031C6.37594 12.8228 5.0106 11.8898 3.40344 11.9234C1.9227 11.9551 0.582649 12.9918 0.143743 14.4429C-0.0118509 14.9603 -0.00407122 15.4913 0.00306019 16.0047C0.00500511 16.133 0.00695004 16.2605 0.00630173 16.3881C0.00500511 16.5804 0.0640013 16.7403 0.176807 16.8517C0.276647 16.9508 0.412143 17.0019 0.564496 17C0.889299 16.9954 1.11102 16.7455 1.11686 16.3777C1.11945 16.2204 1.1188 16.0604 1.11815 15.9057C1.11815 15.7425 1.1175 15.5741 1.1201 15.4084C1.14214 14.1567 2.07376 13.1589 3.33537 13.0352C4.53798 12.918 5.66215 13.7514 5.8936 14.9337C5.95519 15.2491 5.95584 15.5851 5.95648 15.9109C5.95648 16.0235 5.95648 16.1362 5.95973 16.2482C5.97399 16.8666 6.26767 17 6.51792 17C6.52311 17 6.52829 17 6.53283 17C6.68713 16.9967 6.81225 16.9456 6.90496 16.8491C7.02749 16.7209 7.08584 16.5202 7.07936 16.2508C7.07547 16.0831 7.12279 15.9529 7.23754 15.817C8.94778 13.7837 10.6833 11.7136 12.3611 9.71149L13.2999 8.59128H13.2986Z"
        fill="currentColor"
      />
      <path
        d="M6.38106 4.99232C6.13276 5.05124 5.98754 5.22737 5.97198 5.48896C5.95642 5.74992 6.08349 5.94741 6.32077 6.03094C6.44136 6.07368 6.57491 6.0795 6.70068 6.08015C7.41836 6.08209 8.13669 6.08209 8.85436 6.08209C9.12601 6.08209 9.3983 6.08209 9.66994 6.08209H10.4862H11.2434C12.2528 6.08209 13.2622 6.08209 14.2716 6.08209H14.3105C14.389 6.08209 14.4694 6.08274 14.5491 6.06914C14.8409 6.01864 15.0276 5.80042 15.0237 5.51357C15.0198 5.22931 14.8272 5.01757 14.5323 4.97483C14.4512 4.96318 14.3708 4.96383 14.2917 4.96318H14.2807C11.7568 4.96318 9.23363 4.96253 6.70976 4.96318C6.60927 4.96318 6.49257 4.96577 6.38106 4.99167V4.99232Z"
        fill="currentColor"
      />
      <path
        d="M8.59588 4.09547C9.00821 4.09742 9.42442 4.09807 9.83739 4.09807C10.1051 4.09807 10.371 4.09807 10.6335 4.09807C10.9155 4.09807 11.1982 4.09807 11.4802 4.09807H11.9736C12.7464 4.09807 13.5191 4.09807 14.2913 4.09807H14.3049C14.3918 4.09807 14.4819 4.09806 14.5701 4.07993C14.8592 4.02166 15.0368 3.79697 15.0232 3.50882C15.0103 3.23427 14.8255 3.03613 14.5415 2.9921C14.4495 2.97785 14.3548 2.97656 14.2653 2.97656C12.4157 2.97591 10.5654 2.97591 8.71582 2.97656C8.62505 2.97656 8.50577 2.9798 8.38972 3.00246C8.16411 3.04649 8.01889 3.19348 7.97026 3.42788C7.93525 3.59559 7.97026 3.75488 8.06881 3.87597C8.18356 4.01713 8.37027 4.09547 8.59588 4.09612V4.09547Z"
        fill="currentColor"
      />
      <path
        d="M3.61275 5.96183C2.9048 5.94953 2.23704 6.20659 1.7346 6.68317C1.23151 7.16039 0.948852 7.80532 0.93783 8.49946C0.926809 9.21627 1.19197 9.88904 1.68468 10.3928C2.16832 10.8875 2.81728 11.1634 3.51227 11.1692C3.52005 11.1692 3.52847 11.1692 3.53625 11.1692C4.9768 11.1692 6.11912 10.0341 6.14181 8.57522C6.1632 7.18111 5.00532 5.98384 3.61275 5.96118V5.96183ZM4.59365 9.61773C4.31422 9.89616 3.9395 10.049 3.53885 10.049C2.71744 10.049 2.04709 9.38527 2.04449 8.56939C2.0419 7.7464 2.70642 7.07297 3.52523 7.06779C3.52847 7.06779 3.53172 7.06779 3.53496 7.06779C3.93043 7.06779 4.30256 7.22061 4.58327 7.49969C4.86918 7.7833 5.02672 8.16275 5.02672 8.56875C5.02672 8.96697 4.87307 9.33994 4.59365 9.61838V9.61773Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_2957_7459">
        <rect width="19" height="17" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
