export const ChevronRightIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="7"
    height="12"
    viewBox="0 0 7 12"
    fill="none"
    {...props}
  >
    <path d="M1 1L6 6L1 11" stroke="currentColor" />
  </svg>
)
