export const BackIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    {...props}
  >
    <path
      d="M6.66634 13.6667L7.84134 12.4917L3.19134 7.83337L13.333 7.83337L13.333 6.16671L3.19134 6.16671L7.84967 1.51671L6.66634 0.333374L-0.000325494 7.00004L6.66634 13.6667Z"
      fill="#C2C2C2"
    />
  </svg>
)
