export const AposIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="13"
    viewBox="0 0 15 13"
    fill="none"
    {...props}
  >
    <path
      d="M15 2.9441H12.7158C12.2759 2.9441 12.0559 3.09639 12.0559 3.40095V6.04049H15V12.6901H9.16255V3.24867C9.16255 2.16578 9.41636 1.35361 9.92396 0.812165C10.4316 0.270722 11.1591 0 12.1067 0H15V2.9441ZM0.736328 3.24867C0.736328 2.16578 0.99013 1.35361 1.49773 0.812165C2.00534 0.270722 2.7329 0 3.68043 0H6.52301V2.9441H4.2388C3.83271 2.9441 3.62967 3.09639 3.62967 3.40095V6.04049H6.52301V12.6901H0.736328V3.24867Z"
      fill="currentColor"
    />
  </svg>
)
