export const ClockIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 14 14"
    id="Circle-Clock--Streamline-Core"
    height="14"
    width="14"
    {...props}
  >
    <g id="Circle-Clock--Streamline-Core">
      <path
        id="Vector"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7 13.5c3.5899 0 6.5 -2.9101 6.5 -6.5C13.5 3.41015 10.5899 0.5 7 0.5 3.41015 0.5 0.5 3.41015 0.5 7c0 3.5899 2.91015 6.5 6.5 6.5Z"
        strokeWidth="1"
      ></path>
      <path
        id="Vector_2"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7 4.5V7l2.54 2.96"
        strokeWidth="1"
      ></path>
    </g>
  </svg>
)
