export const GraduationIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 14 14"
    id="Graduation-Cap--Streamline-Core"
    height="14"
    width="14"
    {...props}
  >
    <g id="Graduation-Cap--Streamline-Core">
      <path
        id="Rectangle 645"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m7 1.36743 6.5 2.81629L7 7 0.5 4.18372 7 1.36743Z"
        strokeWidth="1"
      ></path>
      <path
        id="Ellipse 346"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m3.44922 5.46851 0.00644 3.0642S4.52881 9.95288 6.9997 9.95288s3.5498 -1.42017 3.5498 -1.42017l-0.0007 -3.0642"
        strokeWidth="1"
      ></path>
      <path
        id="Ellipse 347"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M1.69531 10.6013V4.71069"
        strokeWidth="1"
      ></path>
      <path
        id="Vector"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M1.69594 12.9932c0.6605 0 1.19594 -0.5354 1.19594 -1.1959s-0.53544 -1.196 -1.19594 -1.196S0.5 11.1368 0.5 11.7973s0.53544 1.1959 1.19594 1.1959Z"
        strokeWidth="1"
      ></path>
    </g>
  </svg>
)
