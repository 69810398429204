export const CloseIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    {...props}
  >
    <path
      d="M7.06 6L11.78 1.28C11.92 1.14 12 0.95 12 0.75C12 0.34 11.66 0 11.25 0C11.05 0 10.86 0.0800002 10.72 0.22L6 4.94L1.28 0.22C1.14 0.0800002 0.96 0 0.75 0C0.34 0 0 0.34 0 0.75C0 0.95 0.09 1.14 0.22 1.28L4.94 6L0.22 10.72C0.09 10.86 0 11.04 0 11.25C0 11.66 0.34 12 0.75 12C0.96 12 1.14 11.91 1.28 11.78L6 7.06L10.72 11.78C10.86 11.91 11.05 12 11.25 12C11.66 12 12 11.66 12 11.25C12 11.04 11.92 10.86 11.78 10.72L7.06 6Z"
      fill="currentColor"
    />
  </svg>
)
