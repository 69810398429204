export const GlobeIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 14 14"
    height={14}
    width={14}
    {...props}
  >
    <desc>{'Web Streamline Icon: https://streamlinehq.com'}</desc>
    <g id="web--server-world-internet-earth-www-globe-worldwide-web-network">
      <path
        id="Vector"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7 13.5c3.5899 0 6.5 -2.9101 6.5 -6.5C13.5 3.41015 10.5899 0.5 7 0.5 3.41015 0.5 0.5 3.41015 0.5 7c0 3.5899 2.91015 6.5 6.5 6.5Z"
        strokeWidth={1}
      />
      <path
        id="Vector_2"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M0.5 7h13"
        strokeWidth={1}
      />
      <path
        id="Vector_3"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9.5 7c-0.1228 2.37699 -0.99832 4.6533 -2.5 6.5C5.49832 11.6533 4.6228 9.37699 4.5 7c0.1228 -2.37699 0.99832 -4.65335 2.5 -6.5C8.50168 2.34665 9.3772 4.62301 9.5 7v0Z"
        strokeWidth={1}
      />
    </g>
  </svg>
)
